<template>
    <div>
        <div class="bg-white text-purple rounded p-3">
            <div class="fw-bold fs-5 text-uppercase">API</div>
            <div class="small-text">Acessar documentação da API</div>
            <b-row class="m-0 mt-2">
                <b-col class="p-0">
                    <div class="border rounded d-flex align-items-center p-2">
                        <div class="fw-bold me-2">
                            Documentação:
                        </div>
                        <div>
                            <a class="d-flex align-items-center" :href="user.apiURL || 'https://documenter.getpostman.com/view/18742144/2s93CExGi3'" target="_blank">
                                <b-icon class="d-flex mt-1" icon="link45deg" font-scale="1.25"></b-icon>
                                Postman
                            </a>
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0 ps-3">
                    <div class="border rounded d-flex align-items-center p-2">
                        <div class="fw-bold me-2">
                            Limite:
                        </div>
                        <div>
                            1000/Minuto
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="bg-white text-purple rounded p-3 mt-3">
            <div class="fw-bold fs-5 text-uppercase">Webhook</div>
            <div class="small-text">Configurações de Webhook</div>
            <b-row class="m-0 align-items-end">
                <b-col class="p-0">
                    <b-form-group class="fs-6 fw-bold m-0 mt-2"  label="URL:">
                        <b-form-input class="w-100" v-model="channel.webhookUrl"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="1" class="flex-basis-content">
                    <b-button variant="green" class="text-white" @click="saveChannel">Salvar</b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props: [
        'user'
    ],
    data() {
        return {
            channel: {}
        }
    },
    mounted() {
        this.getChannel()
    },
    methods: {
        async getChannel() {
            const resp = await api.getChannel(this.user.channelId || this.user.roleId)
            console.log('resp',resp)
            if(resp.statusCode != 200) {
                this.$emit('msg', {
                    text: "Ocorreu um erro! Tente novamente mais tarde!",
                    success: false
                })
            } else {
                this.channel  = resp.channel?.channel
            }
        },
        async saveChannel() {
            const save = await api.updateChannel(this.channel)
            // console.log('save',save)
            let msg

            if(save.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro, tente novamente mais tarde!',
                    success: false,
                }
            } else {
                msg = {
                    text: "Webhook salvo com sucesso!",
                    success: true
                }
            }

            if(msg)
                this.$emit('msg',msg)
        }
    }
}
</script>